import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import HomeIcon from "../../images/home.svg"
import * as $ from 'jquery'

export default class PlayerControlExample extends Component {

  componentDidMount() {

    // video
    $("#video-active").on(
      "timeupdate",
      function(event){
        var time = this.currentTime;
        onTrackedVideoFrame(time);
      }
    );

    $('video').on('play',function(){
      $(this)[0].webkitEnterFullscreen();
    });

    $('video').on('ended',function(){
      $(this)[0].webkitExitFullscreen();
    });

    // track video time
    function onTrackedVideoFrame(currentTime){

      var video = document.getElementById("video-active");

      if(currentTime > 14.75 && currentTime < 15) {

        video.pause();

      } else if (currentTime > 22 && currentTime < 22.25) {

        video.pause();

      } else if (currentTime > 29.75 && currentTime < 30) {

        video.pause();

      } else if (currentTime > 36.75 && currentTime < 37) {

        video.pause();

      } else if (currentTime > 40 && currentTime < 40.25) {

        video.pause();

      } else if (currentTime > 43.75 && currentTime < 44) {

        video.pause();

      } else if (currentTime > 56.75 && currentTime < 57) {

        video.pause();

      }
    }
  }

  render() {

    return (
      <Layout>

        <Link to="/" className="gohome">
          <HomeIcon />
        </Link>

        <section className="video-page text-center">
          <div className="container">

            <h1>Basic Orion Process</h1>

            <div className="video-holder">
              <video
                id="video-active"
                className="video-active" preload="auto" controls
                >
                <source src="https://player.vimeo.com/external/408750725.hd.mp4?s=08a1c2504b4992be84e79ea259ac4c3b47428427&profile_id=175" type='video/mp4' />
              </video>
            </div>

            <h3>Next: Base Orion Unit Plus Options</h3>
            <Link to="/videos/video-2" className="btn plus-before">Watch Video</Link>
          </div>
        </section>
      </Layout>
    );
  }
}
